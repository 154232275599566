import { Controller } from "@hotwired/stimulus"
import { FetchRequest, get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }

  connect() {
    super.connect()
  }

  get(event) {
    event.preventDefault();
    this.showStatus()

    const request = get(this.urlValue, { responseKind: "turbo-stream" })
    request.then((response) => {
      this.hideStatus()
      return response
    })
  }

  showStatus() {
    this.dispatch("show-status")
  }

  hideStatus() {
    this.dispatch("hide-status")
  }

  disconnect() {
  }
}