import BulkController from "./bulk_controller"
import {enter, leave } from 'el-transition'
import { post } from '@rails/request.js'

const UpsertStatusConstants = Object.freeze({
  ACTIVE:     'active',
  ERROR:      'error',
  INACTIVE:   'inactive',
  PROCESSING: 'processing',
  SUCCESS:    'success',
})

const Defaults = Object.freeze({
  CHECKBOX_INPUT: 'input[type="checkbox"]',
  ENTITY_CLASS:   '.entity',
  IDS_KEY:        'ids[]',
  TURBO_STREAM:   'turbo-stream',
  TARGETS:        ['button', 'status']
})

export default class extends BulkController { 
  static classes = Object.values(UpsertStatusConstants)
  static targets = Defaults.TARGETS
  static values = { url: String }

  connect() {
    super.connect()
  }

  upsert(event) {
    event.preventDefault()

    if (this.noCheckboxesSelected) { return null }

    // this.showProcessingStatus()

    let data = new FormData()

    data.append( Defaults.IDS_KEY , this.selectedIds)

    this.postRequest(this.urlValue, data)
  }

  toggleCheckboxDisabledState() {
    if (this.selectedAll) {
      this.toggleAllCheckboxElements()
    } else {
      this.selected.forEach((checkbox) => this.toggleElementDisabledState(checkbox))
    }
  }

  toggleAllCheckboxElements() {
    this.element.querySelectorAll( Defaults.CHECKBOX_INPUT ).forEach((checkbox) => this.toggleElementDisabledState(checkbox))
  }

  toggleElementDisabledState(el) {
    el.disabled ? el.disabled = false : el.disabled = true;
  }

  // showProcessingStatus() {
  //   // debugger
  //   this.buttonTarget.classList.add(this.processingClass)
  // }

  // hideProcessingStatus() {
  //   this.buttonTarget.classList.remove(this.processingClass)
  // }

  hideProcessedEntities() {
    this.selected.forEach((checkbox) => leave(checkbox.closest( Defaults.ENTITY_CLASS )) )
  }

  postRequest(url, data) {
    const request = post(url, { body: data, responseKind: Defaults.TURBO_STREAM })
    return this.processRequest(request)
  }

  processRequest(request) {
    // disable button
    this.toggleElementDisabledState(this.buttonTarget)
    // disable checkboxes
    this.toggleCheckboxDisabledState()

    return request.then((response) => {
      if (response.ok) {
        this.responseOkPromise()
        return response
      } else {
        // toggle checkbox disabled state
        this.toggleCheckboxDisabledState()
        // toggle button disabled state
        this.toggleElementDisabledState(this.buttonTarget)
      }
    })
  }

  responseOkPromise() {
    // hide processed entities
    // toggle checkbox disabled state
    // unselect all checkboxes
    // toggle button disabled state
    Promise.all([
      this.hideProcessedEntities(), 
      this.toggleCheckboxDisabledState(), 
      this.unselectAll(), 
      this.toggleElementDisabledState(this.buttonTarget)
    ])
  }
}
