import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    this.initTomSelect()
  }

  disconnect() {
    this.select.destroy()
  }

  initTomSelect() {
    if (this.element) {

      this.select = new TomSelect(this.element, {
        valueField: 'alpha2',
        labelField: 'alpha3',
        searchField: ['alpha3'],
        maxItems: 1,
        selectOnTab: true,
        closeAfterSelect: true,
        create: false,
        openOnFocus: true,
        highlight: true,
        render: {
          option: function (data, escape) {
            return '<div>' +
              '<span class="block">' + escape(data.alpha3) + '</span>' +
              '</div>';
          }
        }
      })

    }
  }
}
