import { Controller } from "@hotwired/stimulus"

import {TabulatorFull as Tabulator} from 'tabulator-tables';
import { FetchRequest } from "@rails/request.js"
// Connects to data-controller="tabulator"
export default class extends Controller {

  connect() {
    super.connect()
    let table = new Tabulator(this.element, {
      responsiveLayout:"collapse",
      height:800,
      ajaxURL:'/items/items_table',
      // layout:"fitColumns",
      columns:
        [
          // // {field:"is_active", width: 10, hozAlign:"center", formatter:"tickCross"},
          {title:"Name", field:"name"},
          {title:"Vendor", field:"vendor_name"},


          // {title:"Price", field:"unit_price", formatter:"money", hozAlign:"right", sorter:"unit_price", formatterParams:{symbol:"$"}, 
          //   headerFilter:"input", headerFilterPlaceholder:"Min. Price", headerFilterFunc:">="
          // },
        ],
    });
  }

  update() {
  }

  end() {
  }


  disconnect() {
    this.table.destroy();
  }
}