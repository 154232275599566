import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'
import { Sortable, MultiDrag } from "sortablejs"

export default class extends Controller {
  connect() {
    super.connect()
    this.sortable = Sortable.create(this.element, {
      group: "line_items",
      scroll: true,  
      delay: 75,
      delayOnTouchOnly: true,
      swapThreshold: 0.75,
      animation: 100,
      filter: ".ignore-elements",
      fallbackOnBody: false,
      selectedClass: 'selected',
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let item_id = event.item.dataset.lineItemId
    
    let move_to_package_id = event.to.dataset.packageId

    let url = event.item.dataset.url.replace(":package_id", move_to_package_id).replace(":id", item_id)

    const request = patch(url, { responseKind: "turbo-stream" })
    request.then((response) => {
      
    })
  }
}
